import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import {
  Breakpoint,
  Color,
  IconButton,
  Card,
  CardContent,
  CardTitle,
  CardImage,
} from '@gleerups/syntax4';
import { useDispatch } from 'react-redux';
import { useMedia } from 'react-use';
import DeleteOutlineOutlinedIcon from '@material-ui/icons/DeleteOutlineOutlined';

import LicenseStatus from '../licenseStatus';

import { COMPLEMENT } from '../../constants';
import './ProductCard.css';
import { LicenseType, Product } from '../types';
import CardMenu from '../Card/CardMenu';
import CardTagList from '../Card/CardTagList';
import { hideProduct } from '../productsReducer';
import api from '../../api/api';
import licenseExpirationInfo from '../Card/licenseExpirationInfo';

const removeExpiredLicense = async (licenseId: string) => {
  await api.removeExpiredLicense(licenseId);
};

const flushProductsCache = async () => {
  await api.flushProductCache();
};

type ProductCardProps = {
  product: Product;
  isTeacher: boolean;
};

const CardWrapper = styled(Card)`
  @media (${Breakpoint.smallMax}) {
    img {
      border-radius: 0.25rem 0 0 0.25rem;
    }
  }
`;

const CardContentWrapper = styled(CardContent)`
  padding: 0.5rem;
`;

const SubtitleWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Subtitle = styled.span<{ expiresSoon?: boolean; expired?: boolean }>`
  color: ${(props) => (props.expiresSoon || props.expired ? Color.lavaRed : Color.grey600)};
  font-size: 10px;
`;

/**
 * Returns the educationalMaterials link for the given product that
 * is stored in local storage.
 */
const getLastVisitedFromLocalStorage = (
  product: Product,
  storage: Storage = window.localStorage
): string | null => {
  const allKeys = Object.keys(storage);
  const materialLinks = product.educationalMaterials.map((em) => em.materialLink);

  const materialLink = allKeys
    .filter((k) => k.startsWith('lastVisited-'))
    // Do not include invalid keys caused by a bug where slug is undefined.
    // Users already have these values in their local storage.
    .filter((k) => !k.includes('-undefined'))
    .map((k) => storage.getItem(k) || '')
    .find((link) => link && materialLinks.includes(link));

  return materialLink || null;
};

const ProductCard = ({ product, isTeacher }: ProductCardProps) => {
  const [lastVisitedLink, setLastVisitedLink] = useState<string>('');

  const removeProduct = () => {
    removeExpiredLicense(product.license.id);
    flushProductsCache();
    dispatch(hideProduct(product.id));
  };

  const dispatch = useDispatch();

  const isGroup = product.educationalMaterials?.length > 0;

  useEffect(() => {
    if (isGroup) {
      const lastVisitedFromLocalStorage = getLastVisitedFromLocalStorage(product);
      const { educationalMaterials: [{ materialLink: firstLinkInGroup = '' } = {}] = [] } = product;
      setLastVisitedLink(lastVisitedFromLocalStorage || firstLinkInGroup);
    }
  }, []);

  const expired = product.license.status === LicenseStatus.EXPIRED;
  const isComplement = product.type === COMPLEMENT;

  const smallScreen = useMedia(`(${Breakpoint.smallMax})`);

  const { expires, type } = product.license;
  const isTrial = type === LicenseType.Trial;

  const { expiresSoon, diff } = licenseExpirationInfo(expires, type);

  let subtitleComputedText = '';
  const subtitleTextType = isTrial ? 'Testlicens' : 'Licens';

  if (expired) {
    subtitleComputedText = `${subtitleTextType} utgången`;
  } else if (expiresSoon) {
    subtitleComputedText = `${subtitleTextType} går ut om ${diff} dagar`;
  } else if (isComplement) {
    subtitleComputedText = 'Komplement';
  } else if (type === LicenseType.Preview) {
    subtitleComputedText = 'Förhandstitt';
  } else {
    subtitleComputedText = 'Testlicens';
  }

  return (
    <CardWrapper
      href={isGroup ? lastVisitedLink : product.materialLink}
      aria-label={product.title}
      disabled={expired}
      role="link"
    >
      <CardImage
        thumbnailSrc={product.thumbnailSrc}
        aspectRatio="47%" // according to CONX-760. aspectRatio 47% gives width 126px
        disabled={expired}
        aria-hidden
      >
        <CardTagList
          expired={expired}
          isComplement={isComplement}
          product={product}
          isTeacher={isTeacher}
        />
      </CardImage>
      <CardContentWrapper>
        <>
          <div>
            <CardTitle aria-hidden disabled={expired} title={product.title} />
            <SubtitleWrapper>
              {((expiresSoon && isTeacher) ||
                expired ||
                isTrial ||
                isComplement ||
                type === LicenseType.Preview) &&
                smallScreen && (
                  <Subtitle expiresSoon={expiresSoon} expired={expired}>
                    {subtitleComputedText}
                  </Subtitle>
                )}
              {smallScreen && product.additionalCardBadge && (
                <Subtitle>{product.additionalCardBadge}</Subtitle>
              )}
            </SubtitleWrapper>
          </div>
          {isTeacher && <CardMenu product={product} removeProduct={removeProduct} />}
          {!isTeacher && expired && (
            <IconButton
              aria-label="Ta bort"
              icon={<DeleteOutlineOutlinedIcon />}
              onClick={removeProduct}
              isSmall
            />
          )}
        </>
      </CardContentWrapper>
    </CardWrapper>
  );
};

export default ProductCard;
